import React from 'react';

import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { month: 'Ene', qualified: 20, unqualified: 10 },
  { month: 'Feb', qualified: 30, unqualified: 20 },
  { month: 'Mar', qualified: 35, unqualified: 18 },
  { month: 'Abr', qualified: 20, unqualified: 22 },
  { month: 'May', qualified: 40, unqualified: 25 },
  { month: 'Jun', qualified: 30, unqualified: 16 },
  { month: 'Jul', qualified: 37, unqualified: 30 },
  { month: 'Ago', qualified: 20, unqualified: 10 },
  { month: 'Sep', qualified: 30, unqualified: 20 },
  { month: 'Oct', qualified: 33, unqualified: 18 },
  { month: 'Nov', qualified: 30, unqualified: 22 },
  { month: 'Dic', qualified: 45, unqualified: 25 },
];

export default function QualifiedLeadsChart() {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-5 rounded-lg shadow-md border border-gray-200">
          <p className="text-sm font-bold text-gray-700">Dia: {label}</p>
          <ul className="mt-2">
            {payload.map((entry, index) => (
              <li key={`item-${index}`} className="text-sm text-gray-600 my-1" style={{ color: entry.color }}>
                {entry.name === 'qualified' ? 'Calificados' : 'No Calificados'}:{' '}
                <span className="font-medium">{entry.value} Clientes</span>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };
  return (
    <div className=" bg-white shadow-lg rounded-3xl overflow-hidden border-2">
      <div className="">
        <h2 className="text-2xl font-bold text-gray-800 px-5 mt-5">Tendencia de Leads Calificados</h2>
        <p className="text-sm text-gray-600 text-center pt-5">
          Visualización independiente de los leads calificados y no calificados durante el mes.
        </p>
        <div className="h-60">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip
                content={<CustomTooltip />}
                formatter={(value, name) => [
                  `${value} Clientes`,
                  name === 'qualified' ? 'Calificados' : 'No Calificados',
                ]}
              />
              <defs>
                <linearGradient id="colorQualified" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0070c1" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#0c94eb" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUnqualified" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#b9e1fe" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#e0f0fe" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <Area type="monotone" dataKey="qualified" stroke="#015ca3" fillOpacity={1} fill="url(#colorQualified)" />
              <Area
                type="monotone"
                dataKey="unqualified"
                stroke="#0c94eb"
                fillOpacity={1}
                fill="url(#colorUnqualified)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
