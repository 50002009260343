import Monday from "../../public/images/Monday-Com-Logo.png";
import SCI from "../../public/images/e-core-logo-oscuro.webp";
import PlanOk from "../../public/images/Logo-Planok-color.png";
import Salesforce from "../../public/images/salesforce_logo.svg";
import Hubspot from "../../public/images/hubspot_logo.svg";
import Zoho from "../../public/images/zoho_crm.webp";
import Pipedrive from "../../public/images/pipedrive_logo.svg";
import Sheets from "../../public/images/sheets_logo.svg";
import Clientify from "../../public/images/clientify.png.webp";
import Constant_Contact from "../../public/images/constant_contact.svg";
import DataHunter from "../../public/images/data_hunter.webp";

export const Integracion = [
	{
		id: 1,
		name: "Monday",
		icon: Monday,
	},
	{
		id: 2,
		name: "Salesforce",
		icon: Salesforce,
	},
	{
		id: 3,
		name: "HubSpot",
		icon: Hubspot,
	},
	{
		id: 4,
		name: "Pipedrive",
		icon: Pipedrive,
	},
	{
		id: 5,
		name: "Google Sheet",
		icon: Sheets,
	},
	{
		id: 6,
		name: "Zoho CRM",
		icon: Zoho,
	},
	{
		id: 7,
		name: "Clientify",
		icon: Clientify,
	},
	{
		id: 8,
		name: "SCI",
		icon: SCI,
	},
	{
		id: 9,
		name: "Plan Ok",
		icon: PlanOk,
	},
	{
		id: 10,
		name: "Constant Contact",
		icon: Constant_Contact,
	},
	{
		id: 11,
		name: "Data Hunter",
		icon: DataHunter,
	},
];
