import { BrowserRouter } from 'react-router-dom';
import Video from './components/Casos de Exito/Video';
import Footer from './components/Footer/Footer';
import Form from './components/Form/Form';
import NewHero from './components/Hero/NewHero';
import NewInfo from './components/Info/NewInfo';
import Services from './components/Services/Services';
import Inmobiliarias from './components/Slider/Inmobiliarias';
import Integraciones from './components/Slider/Integraciones';
import NavBar from './components/navbar/navbar';
import './index.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <NewHero />
        <Integraciones />
        {/* <Info /> */}
        <NewInfo />
        {/* <Whatsapp /> */}
        <Services />
        <Inmobiliarias />
        <Video />
        <Form />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
