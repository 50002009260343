import { Inmobiliaria } from '../../utils/Inmobiliarias';

const Inmobiliarias = () => {
  return (
    <section className="lg:my-32 my-0">
      <h2 className="text-4xl xxl:mx-[5.7em] xl:mx-20 font-medium xs:hidden">
        Clientes que han<span className="text-primary"> confiado en nosotros</span>
      </h2>
      <div className="w-full h-full relative flex-nowrap dark:bg-raisin-black items-center justify-between overflow-hidden">
        <div className="absolute left-0 w-full h-full bg-gradient-to-r to-transparent dark:from-raisin-black dark:to-transparent z-[1]"></div>
        <div className="absolute right-0 w-full h-full bg-gradient-to-l to-transparent dark:from-raisin-black dark:to-transparent z-[1]"></div>
        <div className="h-[70px] my-16 w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
          <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll grayscale">
            {Inmobiliaria.map((inmobiliarias) => {
              return (
                <li key={inmobiliarias.id} className="mx-8">
                  <img
                    loading="lazy"
                    src={inmobiliarias.icon}
                    alt={inmobiliarias.name}
                    title={inmobiliarias.name}
                    className={`cursor-pointer object-fit w-[180px]
                    ${inmobiliarias.name === 'Gevec' ? 'w-[105px] h-[70px]' : ''}${
                      inmobiliarias.name === 'CBS' ? 'w-[90px] mt-5' : ''
                    }
                    ${inmobiliarias.name === 'Puelo' ? 'w-[90px]' : ''}
                    ${inmobiliarias.name === 'Concreta' ? 'w-[95px]' : ''}
                    ${inmobiliarias.name === '70W' ? 'w-[70px]' : ''}
                    ${inmobiliarias.name === 'Deisa' ? 'w-[130px]' : ''}
                    ${inmobiliarias.name === 'Civilia' ? 'w-[130px]' : ''}
                    ${inmobiliarias.name === 'SIP' ? 'w-[130px]' : ''}
                    ${inmobiliarias.name === 'IRC' ? 'w-[100px] -mt-10' : ''}`}
                  />
                </li>
              );
            })}
          </ul>
          <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll grayscale">
            {Inmobiliaria.map((inmobiliarias) => {
              return (
                <li key={inmobiliarias.id} className="mx-8">
                  <img
                    loading="lazy"
                    src={inmobiliarias.icon}
                    alt={inmobiliarias.name}
                    title={inmobiliarias.name}
                    className={`cursor-pointer object-fit w-[180px]
                    ${inmobiliarias.name === 'Gevec' ? 'w-[105px] h-[70px]' : ''}${
                      inmobiliarias.name === 'CBS' ? 'w-[90px] mt-5' : ''
                    }
                    ${inmobiliarias.name === 'Puelo' ? 'w-[90px]' : ''}
                    ${inmobiliarias.name === 'Concreta' ? 'w-[95px]' : ''}
                    ${inmobiliarias.name === '70W' ? 'w-[70px]' : ''}
                    ${inmobiliarias.name === 'Deisa' ? 'w-[130px]' : ''}
                    ${inmobiliarias.name === 'Civilia' ? 'w-[130px]' : ''}
                    ${inmobiliarias.name === 'SIP' ? 'w-[130px]' : ''}
                    ${inmobiliarias.name === 'IRC' ? 'w-[100px] -mt-10' : ''}`}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Inmobiliarias;
