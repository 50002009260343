import { useRef } from 'react';

import 'animate.css';

import ButtonPrincipal from '../Button/ButtonPrincipal/ButtonPrincipal';
import Info from '../Info/Info';
import Frase from './Frase';

const HeroSection = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);

  return (
    <section
      ref={sectionRef}
      className="h-dvh xs:font-medium mx-5 xl:ml-20 xl:mr-40 xxl:ml-52 xxl:mr-72 flex items-center justify-between xs:mt-10"
    >
      <div>
        <Frase ref={titleRef} />
        <p className="xxl:text-2xl xl:text-xl mt-12 mb-8">
          Convierte tus ventas inmobiliarias con inteligencia artificial.
        </p>
        <ButtonPrincipal href="form" text="¡Conversemos!" />
      </div>
      <Info />
    </section>
  );
};

export default HeroSection;
