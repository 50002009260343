import { Bar, BarChart, Tooltip, ResponsiveContainer } from 'recharts';

const generateMonthlyLeadData = () => {
  const data = [];
  for (let day = 1; day <= 31; day++) {
    data.push({
      day: `Día ${day}`,
      withinHours: Math.floor(Math.random() * 100) + 50,
      outsideHours: Math.floor(Math.random() * 100) + 70,
    });
  }
  return data;
};

const leadData = generateMonthlyLeadData();

export default function LeadChart() {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-5 rounded-lg shadow-md border border-gray-200">
          <p className="text-sm font-bold text-gray-700">Dia: {label}</p>
          <ul className="mt-2">
            {payload.map((entry, index) => (
              <li key={`item-${index}`} className="text-sm text-gray-600 my-1" style={{ color: entry.color }}>
                {entry.name}: <span className="font-medium">{entry.value} Leads</span>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full mx-auto bg-white shadow-xl p-5 xs:px-0 rounded-3xl overflow-hidden border-2">
      <h2 className="text-2xl font-bold text-gray-800 mx-5">Leads por Dia</h2>
      <div className="">
        <div className="h-[300px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={leadData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="withinHours" name="Dentro de horario" stackId="leads" fill="#0c94eb" />
              <Bar dataKey="outsideHours" name="Fuera de horario" stackId="leads" fill="#0070c1" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
