import { useState, useEffect, forwardRef } from 'react';

import 'animate.css';

const Frase = forwardRef(() => {
  const phrases = [
    'No Somos un ChatBot',
    'El cliente al centro, desde el inicio!',
    'Deja que los loros hablen, nosotros vendemos!',
    // "Somos un Gestor de Ventas Inmobiliario",
    'Conocemos a tus clientes, hagamos que se sientan valorados!',
    'Transformamos tus ventas y te acompañamos en cada paso!',
    // "Análisis con Inteligencia de Negocios",
    // "Lideramos el Mercado con Tecnología IA",
  ];

  const [currentText, setCurrentText] = useState('');
  const [loop, setLoop] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const typingSpeed = 90;
  const deletingSpeed = 8;

  useEffect(() => {
    const handleType = () => {
      const currentPhrase = phrases[loop % phrases.length];

      if (!isDeleting) {
        setCurrentText(currentPhrase.substring(0, currentText.length + 1));

        if (currentText === currentPhrase) {
          setTimeout(() => {
            setIsDeleting(true);
          }, 2000);
        }
      } else {
        setCurrentText(currentPhrase.substring(0, currentText.length - 1));

        if (currentText === '') {
          setIsDeleting(false);
          setLoop(loop + 1);
        }
      }
    };

    const speed = isDeleting ? deletingSpeed : typingSpeed;
    const typingTimeout = setTimeout(handleType, speed);

    return () => clearTimeout(typingTimeout);
  }, [currentText, isDeleting, loop, phrases]);

  return (
    <h1
      className={`xxl:w-[720px] xxl:h-[380px] transition-opacity duration-500 ease-in-out xxl:text-[75px] xs:text-[46px] xs:h-[240px] xl:text-6xl xl:h-[250px] lg:w-3/4 leading-tight xxl:font-medium ${
        currentText === 'No Somos un ChatBot' ? 'line-through' : ''
      } ${isDeleting ? 'opacity-5' : 'opacity-100'}`}
    >
      {currentText}
    </h1>
  );
});

export default Frase;
