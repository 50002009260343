import { Integracion } from '../../utils/Integracion.js';

const Integraciones = () => {
  return (
    <section className="lg:my-32 my-0">
      <h2 className="text-4xl xxl:mx-[5.7em] xl:mx-20 mx-5 font-medium xs:hidden">
        Nos integramos <span className="text-primary">con tus reglas</span>
      </h2>
      <div className="w-full h-full relative flex-nowrap dark:bg-raisin-black items-center justify-between overflow-hidden">
        <div className="absolute left-0 w-full h-full bg-gradient-to-r to-transparent dark:from-raisin-black dark:to-transparent z-[1]"></div>
        <div className="absolute right-0 w-full h-full bg-gradient-to-l to-transparent dark:from-raisin-black dark:to-transparent z-[1]"></div>
        <div className="h-[70px] my-16 w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
          <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll grayscale">
            {Integracion.map((integracion) => {
              return (
                <li key={integracion.id} className="mx-8">
                  <img
                    loading="lazy"
                    src={integracion.icon}
                    alt={integracion.name}
                    title={integracion.name}
                    className={`cursor-pointer object-fill w-[180px] 
                      ${integracion.name === 'Monday' ? 'w-[250px]' : ''} ${
                        integracion.name === 'Google Sheet' ? 'w-[210px] ' : ''
                      }
										} ${integracion.name === 'SCI' ? 'w-[200px] ' : ''}
                     ${integracion.name === 'Constant Contact' ? 'w-[250px] ' : ''}
										} ${integracion.name === 'Plan Ok' ? 'w-[170px] h-[75px]' : ''}
                    					} ${integracion.name === 'Zoho CRM' ? 'w-[150px]' : ''}
										} ${integracion.name === 'HubSpot' ? 'w-[220px]' : ''}`}
                  />
                </li>
              );
            })}
          </ul>
          <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll grayscale">
            {Integracion.map((integracion) => {
              return (
                <li key={integracion.id} className="mx-8">
                  <img
                    loading="lazy"
                    src={integracion.icon}
                    alt={integracion.name}
                    title={integracion.name}
                    className={`cursor-pointer object-fill w-[180px] ${
                      integracion.name === 'Monday' ? 'w-[250px]' : ''
                    } ${integracion.name === 'Google Sheet' ? 'w-[210px] ' : ''}
										} ${integracion.name === 'Constant Contact' ? 'w-[250px] ' : ''}
                     ${integracion.name === 'SCI' ? 'w-[200px] ' : ''}
										} ${integracion.name === 'Plan Ok' ? 'w-[170px] h-[75px]' : ''}
                    					} ${integracion.name === 'Zoho CRM' ? 'w-[150px]' : ''}
										} ${integracion.name === 'HubSpot' ? 'w-[220px]' : ''}`}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

// Agregar Clientify, https://www.constantcontact.com/landing4/partner-offer/es?pn=lwempresas, Zoho

export default Integraciones;
