import { Link as ScrollLink } from 'react-scroll';

const ButtonPrincipal = ({ href, text, onClick, className }) => {
  return (
    <ScrollLink
      to={href}
      smooth={true}
      duration={800}
      offset={0}
      onClick={onClick}
      className={`flex bg-primary py-4 px-12 rounded-3xl text-white transition-all duration-300 hover:scale-105 text-md cursor-pointer w-fit font-semibold hover:bg-palete ${className}`}
    >
      <span>{text}</span>
    </ScrollLink>
  );
};

export default ButtonPrincipal;
