import Buydepa from '../../public/images/buydepa.svg';
import Civilia from '../../public/images/civilia.jpg';
import Concreta from '../../public/images/concreta.png';
import IRC from '../../public/images/irc.webp';
import Independencia from '../../public/images/logo-independencia.png';
import W from '../../public/images/logo_70W.svg';
import SIP from '../../public/images/sip.png';
import CBS from '../assets/images/CBS.svg';
import Deisa from '../assets/images/Deisa1.webp';
import GL from '../assets/images/G&L.svg';
import Gevec from '../assets/images/Gevec.webp';
import Habite from '../assets/images/Habite.svg';
import Manquehue from '../assets/images/Manquehue.png';
import NorteVerde from '../assets/images/NV_306_blanco.webp';
import Puelo from '../assets/images/Puelo.webp';
import Sento from '../assets/images/logo-sento.svg';

export const Inmobiliaria = [
  {
    id: 0,
    name: 'SIP',
    icon: SIP,
  },
  {
    id: 1,
    name: 'GuzmanLarrain',
    icon: GL,
  },
  {
    id: 2,
    name: 'Gevec',
    icon: Gevec,
  },
  {
    id: 4,
    name: 'Norte Verde',
    icon: NorteVerde,
  },
  {
    id: 5,
    name: 'Buydepa',
    icon: Buydepa,
  },
  {
    id: 7,
    name: '70W',
    icon: W,
  },
  {
    id: 8,
    name: 'CBS',
    icon: CBS,
  },
  {
    id: 9,
    name: 'Manquehue',
    icon: Manquehue,
  },

  {
    id: 10,
    name: 'Deisa',
    icon: Deisa,
  },
  {
    id: 12,
    name: 'Habite',
    icon: Habite,
  },
  {
    id: 13,
    name: 'Independencia',
    icon: Independencia,
  },
  {
    id: 14,
    name: 'Sento',
    icon: Sento,
  },
  {
    id: 15,
    name: 'Puelo',
    icon: Puelo,
  },
  {
    id: 16,
    name: 'Concreta',
    icon: Concreta,
  },
  {
    id: 17,
    name: 'IRC',
    icon: IRC,
  },
  {
    id: 18,
    name: 'Civilia',
    icon: Civilia,
  },
];
