import { useRef, useState } from 'react';
import { PiPlayCircleFill } from 'react-icons/pi';

import buydepa from '../../assets/video/Buydepa 2024 beneficios Lidz.mp4';
import buydepa2 from '../../assets/video/Buydepa_2024 intro y cifras.mp4';
import './Video.css';

const videos = [
  {
    src: buydepa,
    title: 'Beneficios Lidz',
    label: 'Video casos de éxitos 1',
  },
  {
    src: buydepa2,
    title: 'Intro y cifras',
    label: 'Video casos de éxitos 2',
  },
];

const Video = () => {
  const videoRef = useRef(null);
  const [mostrarBoton, setMostrarBoton] = useState(true);
  const [videoPausado, setVideoPausado] = useState(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [mostrarBotonIzquierda, setMostrarBotonIzquierda] = useState(false);
  const [mostrarBotonDerecha, setMostrarBotonDerecha] = useState(false);

  const pausarReproducir = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setVideoPausado(false);
    } else {
      videoRef.current.pause();
      setVideoPausado(true);
    }
  };

  const siguienteVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex === videos.length - 1 ? 0 : prevIndex + 1));
    resetVideo();
  };

  const videoAnterior = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex === 0 ? videos.length - 1 : prevIndex - 1));
    resetVideo();
  };

  const resetVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setVideoPausado(true);
    }
  };

  const handleMouseMove = (e) => {
    const videoRect = videoRef.current.getBoundingClientRect();
    const mouseX = e.clientX;

    if (mouseX < videoRect.left + videoRect.width * 0.1) {
      setMostrarBotonIzquierda(true);
    } else {
      setMostrarBotonIzquierda(false);
    }

    if (mouseX > videoRect.right - videoRect.width * 0.1) {
      setMostrarBotonDerecha(true);
    } else {
      setMostrarBotonDerecha(false);
    }
  };

  return (
    <section
      className="video-container m-5 xs:flex xs:flex-col flex gap-10 xl:my-32 xxl:mx-52 xl:mx-20"
      onMouseMove={handleMouseMove}
      id="success"
    >
      <div
        className="video"
        onMouseEnter={() => setMostrarBoton(true)}
        onMouseLeave={() => setMostrarBoton(videoPausado)}
      >
        <div className={`overlay ${videoPausado ? 'active' : ''}`} onClick={pausarReproducir}></div>
        <video
          ref={videoRef}
          loop
          playsInline
          title={videos[currentVideoIndex].title}
          aria-label={videos[currentVideoIndex].label}
          alt={videos[currentVideoIndex].label}
          src={videos[currentVideoIndex].src}
          onClick={pausarReproducir}
        >
          <track src="#" kind="captions" srcLang="es" label="Spanish" default />
        </video>

        {(mostrarBoton || videoPausado) && (
          <div className="button-container" onClick={pausarReproducir}>
            <PiPlayCircleFill
              className="button-pause"
              alt="Botón reproducción"
              aria-label="Botón reproducción"
              title="Botón reproducción"
              loading="lazy"
            />
            <span className="pause-text xs:hidden">Mira nuestros casos de éxito</span>
          </div>
        )}

        {mostrarBotonIzquierda && (
          <button className="nav-button left" onClick={videoAnterior}>
            &lt;
          </button>
        )}
        {mostrarBotonDerecha && (
          <button className="nav-button right" onClick={siguienteVideo}>
            &gt;
          </button>
        )}
      </div>
      <div className="video-descripcion w-[40%] xs:w-full">
        <h3 className="text-4xl font-medium">
          Caso de éxito <span className="text-primary">Buydepa</span>
        </h3>
        <br />
        <p className="text-[16px] xs:text-xs">
          Buydepa, empresa dedicada a la compra y venta de propiedades, se autodenominan como "automotora de
          departamentos". Venden departamentos semi-nuevos, atacando una demanda de mercado donde la clave del éxito
          está en la venta asesorada. Su filosofía de negocio se basa en buscar el crecimiento de la compañía a través
          de las ventas, no en equipo humano. Impacto y Resultados:
        </p>
        <br />
        <ul className="text-sm list-disc list-inside xs:text-xs">
          <li>3X aumento de la tasa de clientes oportunidad.</li>
          <li>$5.000 costo de marketing por lead calificado.</li>
          <li>1 minuto tiempo de contacto. Atención instantánea dentro y fuera de horario laboral.</li>
          <li>100% tasa de respuesta.</li>
          <li>Fortalecimiento del canal interno de ventas, sin depender de estructuras pesadas.</li>
          <li>Escalamiento de la gestión de leads de 70 a 1.000 al mes</li>
        </ul>
      </div>
    </section>
  );
};

export default Video;
