import { useEffect, useState } from 'react';
import { PiUserCircleLight } from 'react-icons/pi';
import { Link as ScrollLink } from 'react-scroll';

import 'animate.css';

import logo1 from '../../../public/images/logo solo.png';
import logo from '../../../public/images/logo_long.png';
import ButtonNavbar from '../Button/ButtonNavbar/ButtonNavbar';
import './NavBar.css';

const links = [
  { name: '¿Porque somos diferentes?', href: 'diferent' },
  { name: 'Servicios', href: 'services' },
  { name: 'Casos De Éxito', href: 'success' },
];

const NavBar = () => {
  const calculateOffset = () => {
    const isSmallScreen = window.innerWidth < 1024;
    return isSmallScreen ? -80 : -120;
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [theme, setTheme] = useState(() => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  });

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleSystemThemeChange = (event) => {
      setTheme(event.matches ? 'dark' : 'light');
    };

    darkModeMediaQuery.addListener(handleSystemThemeChange);
    setTheme(darkModeMediaQuery.matches ? 'dark' : 'light');

    return () => {
      darkModeMediaQuery.removeListener(handleSystemThemeChange);
    };
  }, []);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  useEffect(() => {
    return () => {
      // Restaurar el scroll al desmontar el componente
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <header className="top-0 flex xxl:mx-52 xl:mx-20 mx-5 justify-between pt-[2em] pb-[1em] sticky z-[1000] animation-header">
      <ScrollLink to="top" smooth={true} duration={1000} className="flex items-center gap-2 cursor-pointer">
        <img src={logo1} alt="" className="size-8" id="top" />
        <img src={logo} alt="" id="top" className="transition-all duration-[300ms] contrast-125 w-[7rem] h-7" />
      </ScrollLink>

      <nav className="flex items-center lg:text-base">
        <div className="hidden lg:flex">
          {links.map((x, index) => (
            <ScrollLink
              key={index}
              to={x.href}
              smooth={true}
              duration={800}
              offset={-120}
              className="py-3 mx-5 mx-2 hover:text-palete md:px-0 md:py-0 lg:text-base font-semibold cursor-pointer hover:rounded-3xl transition-all duration-300"
            >
              {x.name}
            </ScrollLink>
          ))}
        </div>
        <div className="flex items-center gap-4 xs:gap-1">
          <ButtonNavbar href="form" text="Hablemos!" />
          <a href="https://app.lidz.ai/sign-in?redirect=/">
            <PiUserCircleLight className="xs:text-4xl text-5xl cursor-pointer hover:bg-primary hover:rounded-full hover:text-white p-1 duration-300 transition-all" />
          </a>
          <button
            className="md:flex lg:hidden p-1 text-gray-600 hover:text-white hover:bg-primary rounded-3xl transition-all duration-300"
            onClick={toggleMenu}
            aria-label="navbar menu"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </nav>
      {/* NavBar Responsive */}
      {isMenuOpen && (
        <div className="lg:hidden xs:w-96 xs:absolute xs:top-16 flex flex-col items-center gap-10 bg-white shadow-2xl p-6 rounded-2xl mt-4 transition-transform duration-300">
          {links.map((x, index) => (
            <ScrollLink
              onClick={toggleMenu}
              key={index}
              to={x.href}
              smooth={true}
              duration={800}
              offset={calculateOffset()}
              className="py-3 px-5 mx-2 font-semibold cursor-pointer hover:bg-gray-100 hover:rounded-3xl transition-all duration-300"
            >
              {x.name}
            </ScrollLink>
          ))}
        </div>
      )}
    </header>
  );
};

export default NavBar;
