import React from 'react';

import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const data = [
  { name: 'Email', value: 397 },
  { name: 'TocToc', value: 204 },
  { name: 'Enlace Inmobiliario', value: 260 },
  { name: 'Facebook', value: 120 },
  { name: 'Google Ads', value: 278 },
  { name: 'Landing Page', value: 189 },
  { name: 'Portal Inmobiliario', value: 239 },
  { name: 'Instagram', value: 349 },
];

const COLORS = ['#015ca3', '#0070c1', '#0c94eb', '#36aefa', '#7cc9fd', '#92ddfe', '#b1e8ff', '#d0f4ff'];

export default function CanalesDonutChart() {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      return (
        <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 text-start">
          <p className="text-sm font-bold text-gray-700">{name}</p>
          <p className="text-sm text-gray-600 mt-1">
            Clientes: <span className="font-medium"> {value}</span>
          </p>
          <p className="text-sm text-gray-600">
            Porcentaje:
            <span className="font-medium">{((value / totalValue) * 100).toFixed(2)}%</span>
          </p>
        </div>
      );
    }
    return null;
  };

  const totalValue = data.reduce((sum, entry) => sum + entry.value, 0);

  return (
    <div className="w-full lg:w-1/3 mx-auto rounded-3xl shadow-lg overflow-hidden px-10 py-5 text-center border-2">
      <h2 className="text-2xl font-bold text-gray-800 pb-5">Canales</h2>
      <div className="h-60">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              innerRadius={40}
              outerRadius={110}
              paddingAngle={0}
              dataKey="value"
              isAnimationActive={true}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]} // Ciclo seguro de colores
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
