import documents from '../../../public/images/documents.jpg';
import gasfiter from '../../../public/images/gasfiter.webp';
import prospection from '../../../public/images/prospection.jpg';
import './Services.css';

const Services = () => {
  return (
    <section className="xxl:mx-52 xl:mx-20 my-32 mx-5 xs:my-10" id="services">
      <h1 className="text-4xl font-medium text-center">Nuestros servicios</h1>
      <p className="text-xl text-center mt-5 mb-10">El cliente al centro, desde el inicio y en todo su viaje.</p>
      <div className="flex gap-10 xs:flex-col">
        <div className="card bg-white border-2 w-1/3 xs:w-auto cursor-pointer hover:shadow-xl transition-shadow shadow-md duration-300">
          <div className="flex items-center justify-center">
            <img
              src={prospection}
              alt=""
              className="w-[100%] xxl:h-[400px] xl:h-[300px] xs:h-[300px] object-cover rounded-s-full object-[50%_20%]"
            />
          </div>
          <div className="mt-8 mb-10 mx-5">
            <h2 className="text-2xl mb-3 font-medium">Prospección</h2>
            <p>
              Alertamos en tiempo real sobre nuevas oportunidades y atendemos necesidades especiales de tus clientes, ya
              sea para coordinar visitas o gestionar situaciones críticas
            </p>
          </div>
        </div>
        <div className="card bg-white border-2 w-1/3 xs:w-auto cursor-pointer hover:shadow-xl transition-shadow shadow-md duration-300 ">
          <img
            src={documents}
            alt=""
            className="w-[100%] xxl:h-[400px] xl:h-[300px] xs:h-[300px] object-cover rounded-s-full object-[50%_20%]"
          />
          <div className="mt-8 mb-10 mx-5">
            <h2 className="text-2xl mb-3 font-medium">Postulación a crédito</h2>
            <p>
              Organizamos toda la información financiera de tus clientes, creando fichas detalladas para facilitar el
              proceso de postulación a créditos hipotecarios
            </p>
          </div>
        </div>
        <div className="card bg-white border-2 w-1/3 xs:w-auto cursor-pointer hover:shadow-xl transition-shadow shadow-md duration-300">
          <div className="flex items-center justify-center">
            <img
              src={gasfiter}
              alt=""
              className="w-[100%] xxl:h-[400px] xl:h-[300px] xs:h-[300px] object-cover rounded-s-full object-[15%_20%]"
            />
          </div>
          <div className="mt-8 mb-10 mx-5">
            <h2 className="text-2xl mb-3 font-medium">Posventa</h2>
            <p>
              Clasificamos las solicitudes de posventa según el nivel de urgencia y generamos alertas automáticas al
              área de operaciones para una atención oportuna y efectiva de cada cliente.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
